import React from 'react'
import PropTypes from 'prop-types'

import styles from './headings.module.scss'
import { classNames } from '@utils'

const Heading = props => {
  const { children, className } = props
  return <h2 className={classNames(styles.heading, className)}>{children}</h2>
}

Heading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default Heading
