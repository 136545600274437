import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import { externalUrl } from '@utils'

const Link = props => {
  const { to, target, children } = props

  const noChildren = {
    ...props,
    children: null,
    // eslint-disable-next-line react/destructuring-assignment,react/prop-types
    target: props['new-tab'] ? '_blank' : target,
    'new-tab': null
  }

  if (!externalUrl(to)) {
    return <GatsbyLink {...noChildren}>{children}</GatsbyLink>
  }
  return (
    <a href={to} target="_blank" rel="noopener noreferrer" {...noChildren}>
      {children}
    </a>
  )
}

Link.propTypes = {
  to: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

Link.defaultProps = {
  to: '/'
}

export default Link
