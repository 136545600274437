import React from 'react'
import PropTypes from 'prop-types'

import { Heading } from '../headings'
import styles from './FeaturedItems.module.scss'
import Item from './Item'

const FeaturedItems = props => {
  const { title, data } = props
  return (
    <section className={styles.featuredItems}>
      <Heading className={styles.heading}>{title}</Heading>
      <div className={styles.container}>
        {data.map(item => (
          <Item key={item.text} {...item} />
        ))}
      </div>
    </section>
  )
}

FeaturedItems.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
      color: PropTypes.string,
    })
  ),
}

export default FeaturedItems
