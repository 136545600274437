import React from 'react'
import PropTypes from 'prop-types'

import Hamburguer from '@components/hamburger'
import { classNames, Breakpoints } from '@utils'
import styles from './nav.module.scss'
import NavItem from './navItem'

const Nav = props => {
  const { items, inverted } = props

  const nav = (
    <nav className={styles.navigation}>
      {items.length && (
        <ul className={classNames(styles.list, (inverted && styles.inverted))}>
          {items.map(item => (
            <NavItem item={item} key={item.title} />
          ))}
        </ul>
      )}
    </nav>
  )

  return (
    <div>
      <Breakpoints>
      {breakpoint => {
        const hamburger = ['xs', 'sm'].includes(breakpoint)
        return hamburger ?
            <Hamburguer inverted={inverted} />
          :
          nav
      }}
      </Breakpoints>
    </div>
  )
}

Nav.defaultProps = {
  inverted: false
}

Nav.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      to: PropTypes.string
    })
  ),
  inverted: PropTypes.bool,
}

export default Nav
