/* eslint-disable no-nested-ternary */
import React from 'react'
import { Link } from 'gatsby'

import Logo from '../../assets/icons/rhombus.svg'
import styles from './Footer.module.scss'
import { classNames } from '@utils'
import { social, menus } from '../../../content/footer.yml'

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.footerContainer}>
      <div className={styles.brand}>
        <Logo style={{ color: 'black' }} />
        <ul className={styles.social}>
          {
            social.map(sn => (
              <li key={sn.style} className={classNames(styles.socialItem, styles[sn.style])}>
                <a
                  href={sn.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  title={sn.name}
                >
                  <span>{sn.name}</span>
                </a>
              </li>
            ))
          }
        </ul>
      </div>
      {
        menus.map(menu => (
          <div key={menu.title} className={styles.menu}>
            <h3 className={styles.menu__title}>{menu.title}</h3>
            <ul className={styles.menu__list}>
              {
                menu.items.map(item => (
                  <li key={item.path}>
                    {/^https?:\/\//.test(item.path)
                      ? <a href={item.path} target="_blank" rel="noopener noreferrer">{item.text}</a>
                      : (/^mailto/.test(item.path)
                        ? <a href={item.path}>{item.text}</a>
                        : <Link to={item.path}>{item.text}</Link>)}
                  </li>
                ))
              }
            </ul>
          </div>
        ))
      }
    </div>
  </footer>
)

export default Footer
