import React from 'react'
import PropTypes from 'prop-types'

import styles from './headings.module.scss'
import { classNames } from '@utils'

const SubHeading = props => {
  const { children, className } = props
  return (
    <h3 className={classNames(styles.subHeading, className)}>{children}</h3>
  )
}

SubHeading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default SubHeading
