import React from 'react'
import PropTypes from 'prop-types'

// eslint-disable-next-line import/no-dynamic-require,global-require
const iconResolver = name => !!name && require(`@icons/${name}`)

const Icon = props => {
  const { name, alt, className } = props
  const extension = name.split('.')[1]
  const IconComponent = iconResolver(name)

  return !!IconComponent && extension === 'svg' ? (
    <IconComponent {...props} />
  ) : (
    <img src={IconComponent} alt={alt} className={className} />
  )
}

Icon.propTypes = {
  name: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string
}

export default Icon
