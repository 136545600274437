import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Menu from './Menu'
import { classNames } from '@utils'
import styles from './hamburger.module.scss'

const Hamburger = props => {
  const { inverted, title } = props

  const [isOpen, setState] = useState(false)

  function _menuToggle() {
    setState(!isOpen)
  }

  return (
    <div className={styles.hamburger}>
      <div
        className={styles.hamburgerClicker}
        onClick={() => _menuToggle()}
        onKeyDown={() => _menuToggle()}
        role="button"
        tabIndex="0"
        label={title}
      />
      <div
        className={
          classNames(
            styles.hamburgerMenu,
            isOpen && styles.hamburgerMenuIsOpen,
            inverted && styles.inverted
          )
        }
      >
        <span />
        <span />
        <span />
        <span />
      </div>
      <Menu active={isOpen} />
    </div>
  )
}

Hamburger.propTypes = {
  title: PropTypes.string,
  inverted: PropTypes.bool,
}

Hamburger.defaultProps = {
  title: 'Open menu',
  inverted: false,
}

export default Hamburger
