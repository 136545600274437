import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

const SEO = props => {
  const { location, pageContext } = props

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)
  const { siteMetadata } = data.site

  const pageSEO = pageContext.seo || siteMetadata

  let title
  let titleTemplate
  if (pageContext.title && !pageContext.title.startsWith(siteMetadata.title)) {
    title = pageContext.title
    titleTemplate = `%s | ${siteMetadata.title}`
  } else if (pageContext.title) {
    title = pageContext.title
  } else {
    title = siteMetadata.title
    titleTemplate = '%s'
  }
  // const { description } = siteMetadata
  const { image } = pageSEO
  const imageURL = location.origin + image
  const viewport = 'width=device-width, initial-scale=1.0'
  const robots = process.env.GATSBY_SEO_INDEX_FOLLOW

  return (
    <Helmet title={title} titleTemplate={titleTemplate}>
      <meta name="description" content={pageSEO.description} />
      <meta name="robots" content={robots} />
      <meta name="viewport" content={viewport} />
      <meta property="og:url" content={location.href} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={pageSEO.description} />
      {image && <meta property="og:image" content={imageURL} />}
      <meta property="og:robots" content={robots} />
      <meta property="og:viewport" content={viewport} />
    </Helmet>
  )
}

SEO.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object
}

SEO.defaultProps = {}

export default SEO
