import React from 'react'

import Link from '@components/link'
import Button from '@components/button'
import styles from './nav.module.scss'

const NavItem = props => {
  const item = {
    title: props.item.title,
    to: props.item.to
  }


  const li = props.item.button ? (
    <li className={styles.item}>
      <Button {...item}>
        {!!item.to && item.title}
      </Button>
    </li>
  ) : (
    <li className={styles.item}>
      {
        /^https?:\/\//.test(item.to) ?
        <a className={styles.link} href={item.to} target="_blank" rel="noopener noreferrer">{item.title}</a>
          : (
<Link className={styles.link} {...item}>
          {item.title}
</Link>
          )
}
    </li>
  )
  return li
}

export default NavItem
