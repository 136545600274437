import React from 'react'
import PropTypes from 'prop-types'

import styles from './FeaturedItems.module.scss'
import Icon from '../icon'

const CoreValuesItem = props => {
  const { text, icon, color } = props
  return (
    <div className={styles.item}>
      <div className={styles.figure}>
        <Icon name={icon} style={{ color }} />
      </div>
      <h3>{text}</h3>
    </div>
  )
}

CoreValuesItem.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string
}

export default CoreValuesItem
