const _window = (this || global).window

const getWindow = () => {
  if (!_window) {
    return _window
  }

  return _window
}

export default getWindow
