import React from 'react'
import PropTypes from 'prop-types'

import Button from '../button'
import styles from './ContactUsCta.module.scss'

const ContactUsCta = props => {
  const { text, buttonText, to } = props
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h3 className={styles.text}>{text}</h3>
        <Button to={to} className={styles.button} icon="arrow.svg">{buttonText}</Button>
      </div>
    </section>
  )
}

ContactUsCta.propTypes = {
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  to: PropTypes.string,
}

export default ContactUsCta
