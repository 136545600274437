import React from 'react'
import PropTypes from 'prop-types'

import { classNames } from '@utils'
import styles from './container.module.scss'

const Container = props => {
  const { children, className } = props

  return (
    <div
      className={
        classNames(
          styles.container,
          className
        )
      }
    >
      {children}
    </div>
  )
}

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default Container
