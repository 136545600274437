import React from 'react'
import PropTypes from 'prop-types'

import { Header } from '@components/header'
import Footer from '@components/Footer'
import SEO from '@components/seo'
import Content from '@components/content'
import { classNames, Breakpoints } from '@utils'
import styles from './layout.module.scss'

const Layout = props => {
  const { location, pageContext, children, contentStyle, imageStyle } = props

  const illustration = (
    <img
      src={pageContext.subHeader.image}
      className={classNames(styles.subHeaderImage, imageStyle)}
      alt=""
    />
  )

  return (
    <>
      {location && pageContext && (
        <SEO location={location} pageContext={pageContext} />
      )}
      <Header>
        <div className={styles.subHeader}>
          <Content
            className={classNames(contentStyle, styles.subHeaderContent)}
            btnStyle={styles.subHeaderBtn}
            negative
            {...pageContext.subHeader.content}
          />
          <Breakpoints>
            {breakpoint => {
              const hideImage = ['xs', 'sm'].includes(breakpoint)
              return !hideImage && pageContext.subHeader.image ? (
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  {illustration}
                </div>
              ) : null
            }}
          </Breakpoints>
        </div>
      </Header>
      <main className={styles.main}>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  contentStyle: PropTypes.string,
  imageStyle: PropTypes.string,
  animation: PropTypes.bool
}

export default Layout
