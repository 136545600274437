import React from 'react'
import PropTypes from 'prop-types'
import Link from '@components/link'
import Icon from '@components/icon'
import styles from './brand.module.scss'
import { classNames } from '@utils'

const Brand = props => {
  const { className, inverted } = props
  return (
    <div className={classNames(styles.brand, className)}>
      <Link to="/" className={styles.link}>
        <Icon name="rhombus.svg" className={classNames(styles.icon, (inverted && styles.inverted))} />
      </Link>
    </div>
  )
}

Brand.defaultProps = {
  inverted: false,
}

Brand.propTypes = {
  className: PropTypes.string,
  inverted: PropTypes.bool,
}

export default Brand
