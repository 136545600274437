import React from 'react'
import PropTypes from 'prop-types'

import Container from '@components/container'
import styles from './header.module.scss'

const SubHeader = props => {
  const { children } = props

  return (
    <div className={styles.subHeader}>
      <Container>{children}</Container>
    </div>
  )
}

SubHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default SubHeader
