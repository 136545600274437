import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown/with-html'

import Button from '@components/button'
import styles from './content.module.scss'
import { classNames } from '@utils'

const Content = props => {
  const {
    tagLine,
    title,
    content,
    secondContent,
    buttons,
    className,
    negative,
    btnStyle,
    titleStyle
  } = props
  const button = (buttons && buttons.length > 0) ? (
    <div className={styles.wrapper}>
      {buttons.map(btn => {
        const btnprops = {
          title: btn.title,
          icon: btn.icon,
          to: btn.link,
          negative: props.negative
        }
        return (
          <div key={btn.link} className={styles.wrapperBtn}>
            <Button {...btnprops} className={btnStyle}>{btn.title}</Button>
          </div>
        )
      })}
    </div>
  ) : null

  return (
    <div className={classNames(styles.container, className)}>
      {!!tagLine && (
        <div
          className={classNames(
            negative && styles.tagNegative,
            styles.tag
          )}
        >
          {tagLine}
        </div>
      )}
      {!!title && (
        <h3
          className={classNames(negative && styles.titleNegative, styles.title, titleStyle)}
        >
          {title}
        </h3>
      )}
      {!!content && (
        <div
          className={classNames(
            negative && styles.contentNegative,
            styles.content
          )}
        >
          <ReactMarkdown
            source={content}
            escapeHtml={false}
            className={styles.text}
          />
        </div>
      )}
      {!!secondContent && (
        <div
          className={classNames(
            negative && styles.contentNegative,
            styles.content
          )}
        >
          <ReactMarkdown
            source={secondContent}
            escapeHtml={false}
            className={styles.text}
          />
        </div>
      )}
      {button}
    </div>
  )
}

Content.propTypes = {
  title: PropTypes.string,
  tagLine: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
  titleStyle: PropTypes.string,
  negative: PropTypes.bool,
  secondContent: PropTypes.string,
  btnStyle: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      link: PropTypes.string.isRequired,
      negative: PropTypes.bool
    })
  )
}

export default Content
