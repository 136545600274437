import React from 'react'
import PropTypes from 'prop-types'

import Link from '@components/link'
import Icon from '@components/icon'
import { classNames } from '@utils'
import styles from './button.module.scss'

const Button = props => {
  const {
    block,
    className,
    negative,
    large,
    icon,
    children,
    title,
    to,
    onClick,
  } = props

  const _classNames = classNames(
    className,
    styles.button,
    block && styles.buttonBlock,
    negative && styles.buttonNegative,
    large && styles.large
  )

  return to
    ? (
      <Link to={to} title={title} className={_classNames}>
        <span className={styles.children}>{children}</span>
        {!!icon && (
          <span className={styles.after}>
            <Icon name={icon} />
          </span>
        )}
      </Link>
    )
    : (
      <button
        type="button"
        onClick={onClick}
        className={
          classNames(
            _classNames,
            styles.buttonNoLink
          )
        }
      >
        {children}
        {!!icon && (
          <span className={styles.after}>
            <Icon name={icon} />
          </span>
        )}
      </button>
    )
}

Button.defaultProps = {
  onClick: null,
}

Button.propTypes = {
  className: PropTypes.string,
  block: PropTypes.string,
  negative: PropTypes.bool,
  large: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  title: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
}

export default Button
