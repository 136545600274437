import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Link from '@components/link'
import menu from '../../../content/menu.yml'
import { classNames } from '@utils'
import styles from './menu.module.scss'

const Menu = props => {
  const { active } = props

  function enableScroll() {
    if (document) {
      document.body.style.overflowY = 'initial'
    }
  }

  useEffect(() => {
    if (active && document) {
      document.body.style.overflowY = 'hidden'
    }
    return () => enableScroll()
  }, [active])

  return (
    <div className={classNames(styles.menu, active && styles.menuActive)}>
      <ul className={styles.list}>
        { menu.burger.map(item => (
          <li key={item.to} className={styles.listItem}>
            <Link
              className={styles.listItemLink}
              {...item}
              activeClassName={styles.listItemLinkActive}
            >
            {item.title}
            </Link>
          </li>
        )) }
      </ul>
    </div>
  )
}

Menu.propTypes = {
  active: PropTypes.bool
}

export default Menu
