import React from 'react'
import PropTypes from 'prop-types'

import Container from '@components/container'
import Brand from '@components/brand'
import Nav from '@components/nav'
import menu from '../../../content/menu.yml'
import { classNames } from '@utils'

import styles from './header.module.scss'

const Header = props => {
  const { fixed, inverted, staticPos } = props
  const _classNames = classNames(
    styles.header,
    (staticPos && styles.static),
    (fixed && styles.fixed),
  )
  return (
    <header className={_classNames}>
      <Container>
        <div className={styles.container}>
          <Brand className={styles.brand} inverted={inverted} />
          <Nav items={menu.links} inverted={inverted} />
        </div>
      </Container>
    </header>
  )
}

Header.defaultProps = {
  inverted: false,
  staticPos: false,
}

Header.propTypes = {
  inverted: PropTypes.bool,
  staticPos: PropTypes.bool,
  fixed: PropTypes.bool,
}

export default Header
